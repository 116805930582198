<template>
    <div>
         <hr> <br>
        <div style="display: flex;justify-content: space-between">
            <h5>积分流水</h5>
        </div>
        <br>
        <div class="searchForm">
            <div>
                会员姓名： <el-input size="small" v-model="search_form.uname" placeholder="请输入会员姓名"  style="width:150px;"></el-input>
            </div>
            <div>
                操作方式： <el-select v-model="search_form.type" placeholder="请选择" size="small">
                <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
            </div>
            <div>
                操作人： <el-input size="small" v-model="search_form.handle_person" placeholder="请输入操作人"  style="width:150px;"></el-input>
            </div>
            <div>
                会员手机号： <el-input size="small" v-model="search_form.phone" placeholder="请输入会员手机号"  style="width:150px;"></el-input>
            </div>
            <div>
                操作时间：  <el-date-picker
                    size="small"
                    v-model="search_form.time"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
            </el-date-picker>
            </div>
            <div>
                <el-button type="primary" size="small" @click="search">检索</el-button>
                <el-button size="small" @click="clearSearch">重置/检索</el-button>
            </div>
            <br>
            <el-button style="float: right;" type="primary" size="small" @click="downlode">下载</el-button>
            <div style="clear: both"></div>
        </div>
        <br>
        <el-table  :data="scoreWaterList"   border  style="width: 100%">
            <el-table-column   align="center"    prop="uname"    label="会员姓名"></el-table-column>
            <el-table-column  align="center"   prop="phone"  label="会员手机号"></el-table-column>
            <el-table-column  align="center"   prop="befor_score"  label="操作前积分"></el-table-column>
            <el-table-column    align="center"     prop="after_score"  label="操作后积分"> </el-table-column>
            <el-table-column    align="center"  prop="handle_person"   label="操作人">
			</el-table-column>
            <el-table-column    align="center"     prop="type"  label="操作方式">
                <template slot-scope="scope">
					<div>
						 {{['','后台操作','员工操作','消费'][scope.row.type]}}
					</div>
                </template>
            </el-table-column>
            <el-table-column label="操作时间" width="180"  align="center" >
              <template slot-scope="scope">
                 <div>
					 {{scope.row.create_at}}
                 </div>
              </template>
            </el-table-column>
		</el-table>
        <div class="page">
            <el-pagination
                    background
                    @size-change="pageChange"
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="page_totle">
            </el-pagination>
        </div>

    </div>
</template>
<script>
    export default {
        data(){
            return {
                options: [{
                    value: '1',
                    label: '后台操作'
                }, {
                    value: '2',
                    label: '员工操作'
                }, {
                    value: '3',
                    label: '消费赠送'
                }],
                search_form:{
                    uname:'',
                    handle_person:'',
                    type:'',
                    phone:'',
                    time:[],
                    start_time:'',
                    end_time:''
                },
                pageSize:10,
                page:1,
                page_totle:0,
                scoreWaterList:[]
            }
        },
        mounted(){
            this.getScoreWaterList()
        },
        methods:{
            downlode(){
                let _this = this
                if(this.search_form.time.length){
                    this.search_form.start_time = this.search_form.time[0]
                    this.search_form.end_time = this.search_form.time[1]
                }else{
                    this.search_form.start_time = ''
                    this.search_form.end_time = ''
                }

                let cid = sessionStorage.getItem('cid')



                if(this.search_form.uname){
                    var uname = '&uname=' + this.search_form.uname
                }else{
                    uname = '&uname='
                }

                if(this.search_form.handle_person){
                    var handle_person = '&handle_person=' + this.search_form.handle_person
                }else{
                    handle_person = '&handle_person='
                }

                if(this.search_form.type){
                    var type = '&type=' + this.search_form.type
                }else{
                    type = '&type='
                }

                if(this.search_form.phone){
                    var phone = '&phone=' + this.search_form.phone
                }else{
                    phone = '&phone='
                }

                if(this.search_form.end_time){
                    var end_time = '&end_time=' + this.search_form.end_time
                }else{
                    end_time = '&end_time='
                }

                if(this.search_form.start_time){
                    var start_time = '&start_time=' + this.search_form.start_time
                }else{
                    start_time = '&start_time='
                }


                // search_form:{
                //     uname:'',
                //         handle_person:'',
                //         type:'',
                //         phone:'',
                //         time:[],
                //         start_time:'',
                //         end_time:''
                // },

                var token = sessionStorage.getItem('token')
                 token = '&token=' + token

                let url = `${_this.$domain}/excelMemberScoreWater?cid=${cid}${uname}${handle_person}${phone}${start_time}${end_time}${type}${token}`
                location.href = url


            },
            search(){
                let _this = this
                if(this.search_form.time.length){
                    this.search_form.start_time = this.search_form.time[0]
                    this.search_form.end_time = this.search_form.time[1]
                }else{
                    this.search_form.start_time = ''
                    this.search_form.end_time = ''
                }

                this.getScoreWaterList()

            },
            clearSearch(){
                this.search_form ={
                        uname:'',
                        handle_person:'',
                        type:'',
                        phone:'',
                        time:[],
                        start_time:'',
                        end_time:''
                }
                this.page=1
                this.pageSize=10
                this.getScoreWaterList()

            },
            pageChange(pageSize){
                this.page=1
                this.pageSize=pageSize
                this.getScoreWaterList()
            },
            handleCurrentChange(page){
                this.page=page
                this.getScoreWaterList()
            },
            getScoreWaterList(){

                let _this = this
                _this.search_form.page=_this.page
                _this.search_form.pageSize=_this.pageSize
                this.$post('/pc/member/getMemberScoreWater', _this.search_form,function (res) {
                    let result = res.data
                    if(result.code==200){
                        _this.scoreWaterList = result.data.result
                        _this.page_totle=result.data.count
                    }
                })
            },
        }
    }
</script>
<style>

</style>
